<template>
  <el-main class="faq-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        常見問題
      </div>
      <div>
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <div slot="title" class="faq-title">
              Q1.運動數據公益可以解決誰的問題?
            </div>
            <div class="faq-content">國內缺乏運動資料的研究單位、政策單位，可以用本計畫蒐集的資料，進行區域或特殊族群的運動與健康分析。</div>
            <div class="faq-content">例如：用資料提升運動安全性(高齡、身障者、高危險群)、建立平權友善的運動環境、或協助政策面推動全民健康與運動推廣發展策略…等。</div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <div slot="title" class="faq-title">
              Q2.誰能提供這些資料?
            </div>
            <div class="faq-content">除了個人或合作的運動場館外，我們更希望邀請國內眾多企業(如台灣百大企業)，由企業領頭，帶員工一起運動、也同時捐資料，甚至加碼做主題式公益捐助(如國泰人壽蒐集運動步數後，在澎湖花嶼國小捐贈太陽能發電設備)。</div>
          </el-collapse-item>
          <el-collapse-item name="3">
            <div slot="title" class="faq-title">
              Q3.會蒐集哪些資料?
            </div>
            <div class="faq-content">我們訂定了63多項運動資料欄位，欄位包含生理數值(身高、體重、心率…等)、體適能資料與運動資料(距離、步數、花費時間、平均速度…等項目)。另外還包括數據基本資料(出生年、性別、縣市…)，目前仍持續增修中。</div>
          </el-collapse-item>
          <el-collapse-item name="4">
            <div slot="title" class="faq-title">
              Q4.這些資料會用在哪邊?
            </div>
            <div class="faq-content">這些資料經過隱私增強技術清理過後，會用在群體量化分析(哪些人、什麼時段、怎樣的運動量)、運動行為科學分析(透過激勵方案，讓民眾增加步行量)，以及與開放資料(如地圖、路線、天氣…等)交疊後，進行運動環境的分析。</div>
          </el-collapse-item>
          <el-collapse-item name="5">
            <div slot="title" class="faq-title">
              Q5.執行後公益的結果會是如何?
            </div>
            <div class="faq-content">例如111年促成希望基金會與運動筆記合作，讓健走步數數位化，透過民眾捐贈資料，同時也累計一萬步就送跑鞋給偏鄉；</div>
            <div class="faq-content">以及促成國內馬拉松結合視障族群，蒐集陪跑者與視障者之跑步資料，針對較難有機會跑步的視障者，分析他們的肌肉張力、剛度、彈性、肌肉健康、身體狀況疲勞度，作為往後訓練依據。</div>
          </el-collapse-item>
          <el-collapse-item name="6">
            <div slot="title" class="faq-title">
              Q6.資料對產業如何運用?
            </div>
            <div class="faq-content">平台生態系數據公益轉型業者(即平台相關運動資料提供之數據公益合作機構、單位或企業)所提供的運動資料資料，若屬以下四類型(如下說明)，預期將對潛在第三方加值創新業者，提供較高的科技加值利用誘因; 且可對整體社會環境、數位經濟健全進步發展做出貢獻：(1)專業運動類型資料；(2)運動訓練、課程統計分析類型資料；(3)運動科技產品驗證、優化的使用軌跡、感測類型資料；(4)可加速創新設備/服務、跨域加值類型機械學習訓練類型資料。</div>
          </el-collapse-item>

          <el-collapse-item name="7">
            <div slot="title" class="faq-title">
              Q7.對產業有什麼幫助?
            </div>
            <div class="faq-content">110年度國內常規運動人口比例33.9%，期望透過數位發展部數位產業署本計畫政策資源，結合國內科技新創能量、數位轉型企業投入與公益機構活動倡議，運用公司數據公益結合運科創新，鼓勵國內運動相關產業發展跨域、科技加值共創應用，帶動新興創新研發設備、APP、服務項目等體驗運動科技民眾增加(運用數位科技結合公益創新，打破年齡、性別、弱勢、身心障、區域資源不均等障礙)，並挹注國內專業運動、職業運動市場科技化發展，促進我們運動人口比例穩定提升、與傳統運動產業藉由數位化科技加值轉型創新。 </div>
          </el-collapse-item>

          <el-collapse-item name="8">
            <div slot="title" class="faq-title">
              Q8.如何成為運動數據公益平台的資料貢獻合作夥伴?
            </div>
            <div class="faq-content">平台歡迎任何認同數據公益並且有意願的場域業者參與。</div>
            <div class="faq-content">目前有數種方式可以成為運動數據公益平台的資料貢獻合作夥伴。您可透過參與
              <a href="https://www.hpa.gov.tw/Pages/Detail.aspx?nodeid=129&pid=16309" target="_blank">
                國健署的運動計畫徵求
              </a>
              而成為合作夥伴，您也可以直接跟平台
              <a @click="()=>{ $router.push('/SportData/Register') }">
                申請
              </a>。
            </div>
          </el-collapse-item>

          <el-collapse-item name="9">
            <div slot="title" class="faq-title">
              Q9.{{ $t(`FAQ.Q9`) }}
            </div>
            <div class="faq-content">
              {{ $t(`FAQ.Q9_Answer`) }}
              <DownloadFileButton
                :button-type="'text'"
                :aria-label="$t('general.Download')+$t('navbar.Compliance_handbook')+$t('File.File')"
                :file-para="'compliance_handbook'"
                :file-name="'運動數據公益平台個資評估法遵使用手冊.pdf'"
                :button-content="$t('general.Download')+$t('navbar.Compliance_handbook')"
              />
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </section>

  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'
import DownloadFileButton from '@/components/Button/DownloadFileButton.vue'

export default {
  name: 'FAQ',
  components: {
    MainContain,
    DownloadFileButton
  },
  data () {
    return {
      specialSetting: false,
      activeNames: ['1']
    }
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.faq-container {
  color: $text_dark;
  font-size: 1rem;
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }

  .el-collapse{
      padding: 1.25rem 0;
      .el-collapse-item{
        .faq-title{
          padding-left: 1rem;
          font-size: 1.25rem;
          line-height: 2rem;
        }
        .faq-content{
          padding: 0 2rem;
          font-size: 1rem;

          a{
            color: $danger;
          }
          a:hover{
            font-weight: bold;
          }
        }
      }
    }
}
</style>
